<template>
  <v-app>
    <v-main>
      <v-container class="pa-0" fluid>
        <v-stepper v-model="step" width="100%" flat>
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step > 1" step="1" v-show="step < 3" :color="step > 1 ? 'success' : 'primary'">
              Beschreibung & Zweck
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2" v-show="step < 4" :color="step > 2 ? 'success' : 'primary'">
              Rechtsgrundlage
            </v-stepper-step>

            <v-divider v-show="step < 4"></v-divider>

            <v-stepper-step :complete="step > 3" step="3" v-show="step < 5" :color="step > 3 ? 'success' : 'primary'">
              Empfänger
            </v-stepper-step>

            <v-divider v-show="step < 5"></v-divider>

            <v-stepper-step :complete="step > 4" step="4" v-show="step < 6 && step > 2"
              :color="step > 4 ? 'success' : 'primary'">
              Datenübermittlung
            </v-stepper-step>

            <v-divider v-show="step < 6 && step > 2"></v-divider>

            <v-stepper-step :complete="step > 5" step="5" v-show="step > 3" :color="step > 5 ? 'success' : 'primary'">
              Datenspeicherung
            </v-stepper-step>

            <v-divider v-show="step > 3"></v-divider>

            <v-stepper-step :complete="step > 6" step="6" v-show="step > 4" :color="step > 6 ? 'success' : 'primary'">
              Hinweise
            </v-stepper-step>

            <v-divider v-show="step > 4"></v-divider>

            <v-stepper-step step="7" v-show="step > 5">
              Ergebnis
            </v-stepper-step>
          </v-stepper-header>

          <v-row>
            <v-col>
              <v-card class="pa-3" color="#F5F5F5" rounded="0" elevation="0">
                <v-row justify="space-between" align="center">
                  <v-col cols="12" sm="5">
                    <v-btn text @click="step -= 1" v-if="step > 1">
                      Zurück
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="2" class="text-center">
                    <v-btn icon @click="openInfo()" v-if="step <= 5">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="5" class="text-right">
                    <v-btn color="primary" depressed @click="step += 1; createResult()" v-if="step < 7">
                      Weiter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step1 ref="step1"></Step1>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step2 ref="step2"></Step2>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step3 ref="step3"></Step3>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step4 ref="step4"></Step4>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step5 ref="step5"></Step5>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>


            <v-stepper-content step="6">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step8 ref="step8"></Step8>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="7">
              <v-container class="pl-0 pr-0">
                <v-row>
                  <v-col>
                    <Step9 ref="step9" :result="result"></Step9>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>

        <v-dialog v-model="dialog" width="50vw">
          <v-card>
            <v-card-text class="pa-3">
              <p v-html="info_text" class="ma-0"></p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    Step1: () => import("@/components/Step1.vue"),
    Step2: () => import("@/components/Step2.vue"),
    Step3: () => import("@/components/Step3.vue"),
    Step4: () => import("@/components/Step4.vue"),
    Step5: () => import("@/components/Step5.vue"),
    Step8: () => import("@/components/Step8.vue"),
    Step9: () => import("@/components/Step9.vue"),
  },
  data: () => ({
    step: 1,
    result: null,
    info_text: "",
    dialog: false
  }),
  methods: {
    createResult() {
      if (this.step !== 7)
        return;

      const result = {
        'beschreibung': this.$refs.step1.getData(),
        'rechtsgrundlage': this.$refs.step2.getData(),
        'empfaenger': this.$refs.step3.getData(),
        'drittland': this.$refs.step4.getData(),
        'speicherung': this.$refs.step5.getData(),
        'dse': this.$refs.step8.getData()
      }

      this.result = result;
    },
    openInfo() {
      const infos = {
        1: "Es ist in diesem Abschnitt eine Beschreibung des Dienstes sowie der Zweck der Verwendung näher auszuführen. Ggf. Nennung der Art von personenbezogenen Daten. Hier sollte versucht werden, den Dienst und die damit verbundene, zweckgebundene Datenverarbeitung möglichst präzise zu beschreiben.",
        2: "Achte darauf, dass bei einigen Rechtsgrundlagen zusätzliche Erwägungen durchgeführt werden müssen (s. Prozess). So ist es beispielsweise erforderlich, bei der Rechtsgrundlage nach Art. 6 Abs.1 lit. f) DSGVO eine Interessenabwägung durchzuführen.",
        3: "Der Begriff des Empfängers ist in Art. 4 Nr. 9 DSGVO definiert. Es sind demnach natürliche und/oder juristische Personen aufzuführen, die unter diese Definition fallen. Hier sind insbesondere Unternehmen zu nennen, welche an der Datenverarbeitung beteiligt sind und in diesem Zusammenhang Daten erhalten, z. B. kann dies das CRM-Tool sein, das Daten durch das Kontaktformular erhält oder Unternehmen, die bestimmte Marketing-Aktionen durchführen oder unterstützen. Gleichzeitig ist es alternativ möglich, Empfängerkategorien anzugeben.",
        4: "Hier gilt es darüber zu informieren, welche personenbezogenen Daten in Drittländer übermittelt werden. Drittländer sind Staaten, die nicht zur EU bzw. zum EWR gehören und in denen die DSGVO nicht direkt Anwendung findet. Für <a href='https://www.datenschutz.rlp.de/fileadmin/lfdi/Dokumente/Pruefschritte_Datenuebermittlung_in_Drittlaender_nach_Schrems_II.pdf'>Übermittlung in Drittländer</a> gilt es die Art. 44 ff. DSGVO zu beachten. Eine Übermittlung ist grundsätzlich nur zulässig, wenn ein <a href='https://datenschutz.hessen.de/datenschutz/internationales/angemessenheitsbeschl%C3%BCsse'>Angemessenheitsbeschluss</a> (Art. 45 DSGVO) oder geeignete Garantien (Art. 46 DSGVO) bestehen. Wenn ein Angemessenheitsbeschluss mit einem Drittland besteht, bedeutet dies, dass ein vergleichbares Schutzniveau zur DSGVO im Drittland besteht und die Übermittlung ohne weiteres möglich ist. Im Falle von geeigneten Garantien haben die sog. Standarddatenschutzklauseln (SCC) vgl. Art. 46 Abs. 1 lit. c) DSGVO eine besondere Praxisrelevanz. Hierbei handelt es sich um Standardverträge, die von der Europäischen Kommission genehmigt wurden und sich ebenfalls für die Rechtfertigung einer Übermittlung eignen, wenn diese Verträge zwischen dem Datenexporteur und Datenimporteur abgeschlossen werden. Neben dem Abschluss von SCC sind ggf. weitere Maßnahmen erforderlich.",
        5: "Hier ist die Dauer der Verarbeitung/Speicherung von personenbezogenen Daten zu nennen. Sofern die Dauer bekannt ist, formuliere einen eigenständigen Text und verlinke die Quelle.",
      }

      this.info_text = infos[this.step];
      this.dialog = true;
    }
  }
};
</script>

<style>
.v-stepper__wrapper {
  height: 100%;
}
</style>
